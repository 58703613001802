<template>
	<main v-if="visible">
		<el-dialog :visible="true" @close="close" @closed="closed" >
			<template slot="title">
				<div class="title">{{query.id ? '编辑' : '创建'}}{{ title }}</div>
			</template>
			<div class="form-box">
				<el-form class="create-form" ref="formRef" :rules="rules" :model="form" label-width="100px">
					<el-form-item label="标题" prop="title">
						<el-input v-model="form.title"/>
					</el-form-item>
					<el-form-item label="内容" prop="content">
						<form-editor v-model="form.content"/>
						<div class="tip">可变参数使用${var}符号包含。例如添加姓名username，即应该添加${username}到正文中。</div>
					</el-form-item>
					<div class="form-flex">
						<el-form-item label="发布状态" prop="status">
							<el-switch v-model="form.status" active-text="正常" inactive-text="草稿"></el-switch>
						</el-form-item>
						<el-form-item label="权重" prop="sort">
							<el-input v-model="form.sort" type="number"></el-input>
						</el-form-item>
					</div>
					<div class="form-flex">
						<el-form-item label="发布时间" prop="create_time">
							<el-date-picker v-model="form.create_time" type="datetime" placeholder="选择日期时间"></el-date-picker>
						</el-form-item>
						<el-form-item label="下架时间" prop="end_time">
							<el-date-picker v-model="form.end_time" type="datetime" placeholder="选择日期时间"></el-date-picker>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" :loadin="submit_loading" @click="submit">保 存</el-button>
			</div>
		</el-dialog>
	</main>
</template>

<script>
import {email_template as api} from "@/api/admin";
import _ from "lodash";
import {timeToString,stringToTime} from "@/service/admin/common";
export default {
	props:{
		title:"",
		column_option:{
			type:Object,
			default:()=>{
				return {}
			}
		}
	},
	data(){
		return {
			query:{},
			form:{},
			show:false,
			visible:false,
			submit_loading:false,
		}
	},
	computed:{
		rules(){
			return {
				title:[
					{required:true,message:"请输入标题"}
				],
				content:[
					{required:true,message:"请输入内容"}
				]
			}
		}
	},
	methods:{
		getDetail(){
			api.get({
				id:this.query.id
			}).then((res)=>{
				const data = res.detail;
				this.form = this.decodeData(data);
			})
		},
		submit(){
			this.$refs['formRef'].validate((valid) => {
				if (!valid) {
					return false;
				}
				var data = _.cloneDeep(this.form);
				data = this.encodeData(data);

				this.submit_loading = true;
				api.create(data).then((res)=>{
					this.close();
					this.$emit('success')
					this.$message.success({
						message:'保存成功',
						duration:1500,
					});
				}).finally(()=>{
					this.submit_loading = false;
				})
			});
		},
		decodeData(data){
			data.status = data.status === 1;
			data.create_time = timeToString(data.create_time);
			data.end_time = timeToString(data.end_time);
			return data;
		},
		encodeData(data){
			data.status = data.status ? 1 : 2;
			data.create_time = stringToTime(data.create_time);
			data.end_time = stringToTime(data.end_time);
			return data;
		},
		init(query = {}){
			this.query = query;
			if(this.query.id){
				this.getDetail();
			}else{
				this.form = {};
			}
			this.visible = true;
		},
		close(){
			this.visible = false;
		},
		closed(){
			this.form = {};
			this.$refs['formRef'].resetFields();
		}
	}
}
</script>
